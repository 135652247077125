.obs {
    background-color: black;
    color: white;
}

.obs.obs-grid {
    border-color: white;
}

textarea {
    background-color: transparent;
    border-radius: 0.2rem;
    font-family: inherit;
    color: white;
    border: 0.125rem solid #c0c0c0
}

textarea:focus {
    outline: none;
}

.form-bkg{
    height:max(10rem, 100vh);

}
